/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Join Halley in gathering firewood"), "\n", React.createElement(_components.p, null, "You drop your backpack and follow Halley into the bushes. What remains of the evening sun is just barely enough to illuminate the twigs on the ground where the forrest isn't too thick. While you're collecting branches and losing the competition of who can carry more at once, you suddenly get a weird feeling in your stomach and you become light-headed. You look over at Halley and notice she's fixating on something behind you."), "\n", React.createElement(_components.p, null, "As you turn around you see.. nothing. Just trees, bushes and rocks. The weird feeling wears off and you ask Halley what she's staring at. She tells you she just thought she saw somebody or something between the leaves, but she was probably just imagining it. Like how your mind sometimes makes you see faces in random patterns."), "\n", React.createElement(_components.p, null, "You finish collecting firewood and return to the clearing. James and Maxine are just finishing setting up the tents and help you build a campfire. You grab the firestarters from your backpack and your lighter and get the campfire going."), "\n", React.createElement(_components.p, null, "As you're warming your body at the fire and chatting with your friends, you get the feeling that you're being watched. You see glimmers of faces in the shadows just outside the campfire's light."), "\n", React.createElement(_components.p, null, "As you bring this up to your friends, they tell you to relax and stop being so paranoid. They jokingly mock you and ask if you've let the scary podcasts get to you."), "\n", React.createElement(_components.p, null, "After some back and forth banter you relax."), "\n", React.createElement(Link, {
    to: "../first-night"
  }, "> Go to sleep"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
